import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'
import { makeUrl } from '../../assets/js/buildURL'
import axios from 'axios'
import toast from 'react-hot-toast'
import { encryptStorage } from '../../assets/js/encryptStorage'


const initialState = {
    user: "",
    client: null,
    userData: {}
}

export const getUserData = createAsyncThunk(
    "getUserData",
    async (user, { getState }) => {
        let data = []
        const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };

        try {
            const URL = makeUrl("user", `getData`)
            const res = await axios.get(URL, { headers })
            if (res.status !== 200) {
                return toast.error(`Error fetching data status ${res.status}`)
            }
            data = res?.data?.data
        }
        catch (e) {
            toast.error(`Error fetching data: ${e.message}`)
            console.error("getAllData Error: ", e.message);
        }

        return data
    }
)

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.user = true
        },
        logoutUser: (state) => {
            state.user = false
            state.client = null
            state.sloif_ids = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserData.fulfilled, (state, action) => {
            state.userData = action.payload
        })
    }
})

export const { loginUser, logoutUser } = userSlice.actions
export default userSlice.reducer