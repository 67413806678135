/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast from "react-hot-toast";
import MetaData from "../../components/MetaData";
import { confirmAlert } from "react-confirm-alert";
import Loader from "../../components/Loader";
import ReactDataTable from "../../components/ReactDataTable";
import tailwindClasses from "../../assets/tailwindClasses";
import axios from "axios";
import { makeUrl } from "../../assets/js/buildURL";
import { validateState } from "../../assets/js/helper";
import CustomButton from "../../components/Reusable/Button/CustomButton";
import { encryptStorage } from "../../assets/js/encryptStorage";
import { getCompanyDetails } from "../../store/slices/companySlice";
import { useDispatch } from "react-redux";

const { componentMainDiv, componentHeaderDiv, componentHeaderFlexDiv } = tailwindClasses;

const Companies = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    crm_name: "hubspot"
  });
  const [showModal, setShowModal] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };

  const handleDisconnect = async (companyId,onClose) => {
    let loadingRef = toast.loading("Disconnecting")
    try {
      let url = makeUrl("user", `hubspot/disconnect/${companyId}`)
      let res = await axios.get(url)
      if (res.status) {
        toast.success("Company Disconnected")
        getAllData()
        onClose()
      }
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
    toast.dismiss(loadingRef)
  }

  const columns = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      width: "80px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Crm Name",
      selector: (row) => row?.crm_name || "-",
      sortable: true,
      wrap: true,
    },
    // {
    //   name: "List id",
    //   selector: (row) => row?.list_id || "-",
    //   sortable: true,
    //   wrap: true,
    // },
    {
      name: "Last Modified At",
      selector: (row) => row?.updated_at || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Actions",
      height: "200px",
      selector: (row) => <div className="p-2 flex flex-col align-center justify-center">{row?.refresh_token ? <button onClick={() => confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="bg-white border-2 rounded-2xl">
              <h1 className="pt-4 px-4 my-4 font-bold">
                Call
              </h1>
              <hr />
              <p className="px-4 my-4">
                Are you sure you want to Disconnect this company?
              </p>
              <hr />
              <div className="flex flex-row justify-between my-2 pb-4 px-4">
                <CustomButton
                  label={"Yes"}
                  classname={"bg-green-500 hover:bg-green-600"}
                  onClick={() => handleDisconnect(row.id,onClose)}
                />
                <CustomButton
                  classname={"bg-red-500 hover:bg-red-600"}
                  label={"No"}
                  onClick={onClose}
                />
              </div>
            </div>
          );
        },
      })} className="bg-red-500 hover:bg-red-600 text-white w-[6.5rem] h-[2rem] rounded-md">Disconnect</button> : <div className="">
        <a className="" href={makeUrl("user", `install/${row.id}/${row.user_id}`)}>
          <button className="text-white bg-blue-500 hover:bg-blue-700 w-[6.5rem] h-[2rem] rounded-md">Connect CRM</button>
        </a>

      </div >}
        <button className="mt-2 text-white bg-red-500 hover:bg-red-700 w-[6.5rem] h-[2rem] rounded-md"
          onClick={() => confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="bg-white border-2 rounded-2xl">
                  <h1 className="pt-4 px-4 my-4 font-bold">
                    Delete
                  </h1>
                  <hr />
                  <p className="px-4 my-4">
                    Are you sure you want to delete this company?
                  </p>
                  <hr />
                  <div className="flex flex-row justify-between my-2 pb-4 px-4">
                    <CustomButton
                      label={"Yes"}
                      classname={"bg-green-500 hover:bg-green-600"}
                      onClick={() => {deleteCompany(row.id,onClose)}}
                    />
                    <CustomButton
                      classname={"bg-red-500 hover:bg-red-600"}
                      label={"No"}
                      onClick={onClose}
                    />
                  </div>
                </div>
              );
            },
          })}
        >Delete</button>
      </div>,
      sortable: true,
      wrap: true,
    },

  ];

  const getAllData = async () => {
    try {
      const URL = makeUrl("company", `getAllData`)
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      const data = res.data.data
      setData(data);
    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`)
      console.error("getAllData Error: ", e.message);
    }
    setLoading(false)
  };

  const deleteCompany = async (id, onClose) => {
    try {
      const URL = makeUrl("company", `delete/${id}`)
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      toast.success("Company deleted successfully")
      setRefresh(!refresh)
      onClose()
    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`)
      console.error("getAllData Error: ", e.message);
    }
    setLoading(false)
  };

  const handleCreate = async () => {
    const validateStateRes = validateState(formData, ["name", "crm_name"])
    if (validateStateRes) {
      return toast.error(validateStateRes)
    }

    else {
      try {
        const URL = makeUrl("company", "create")
        const res = await axios.post(URL, formData, { headers })
        setRefresh(!refresh)
        closeModal()
        setFormData({
          name: "",
          crm_name: "hubspot"
        })
        dispatch(getCompanyDetails())

      }
      catch (error) {
        console.error("handleConnect", error.message)
        toast.error(error.message)
      }
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  const updateData = (key, e) => {
    setFormData({ ...formData, [key]: e.target.value })
  }

  useEffect(() => {
    getAllData();
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [refresh]);

  return (
    <div className="min-h-[89%]">
      <MetaData title={"Companies"} />

      <div className={componentMainDiv}>

        <div className={componentHeaderDiv}>
          <div className={componentHeaderFlexDiv}>
            <div className="text-2xl">Companies</div>
            <div>
              {/* <a className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded" href={makeUrl("user", "install")}>
                Connect CRM
              </a> */}
              <CustomButton label="Create company" className="m-auto" onClick={openModal} loading={loading} classname={"bg-blue-500 hover:bg-blue-600"} />
            </div>
          </div>
        </div>

        {
          showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-[30%] my-6 mx-auto max-w-3xl">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                    <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                      <label className="text-3xl font-semibold">Create Company</label>
                    </div>

                    <div className="flex flex-col items-start  p-5 border-b border-solid border-blueGray-200 rounded-t">
                      <label className="text-base">Enter Company Name</label>
                      <input className="border border-[lightgray] w-[100%] h-[2.3rem] px-3 rounded-md text-black outline-gray-300 my-2 placeholder:text-black placeholder:text-base text-base" placeholder="Name" onChange={e => updateData("name", e)} />
                      <label className="text-base" htmlFor="crms">Select a CRM: </label>
                      <select className="border border-[lightgray] w-[100%] h-[2.3rem] px-3 rounded-md text-black outline-gray-300 my-2 text-base" onChange={e => updateData("crm_name", e)}>
                        <option value={"hubspot"}>Hubspot</option>
                      </select>
                      {/* <label className="text-base">Enter Contact List Id</label>
                      <input className="border border-[lightgray] w-[100%] h-[2.3rem] px-3 rounded-md text-black outline-gray-300 my-2 placeholder:text-black placeholder:text-base text-base" type="number" placeholder="List Id" onChange={e => updateData("list_id", e)} /> */}
                    </div>

                    <div className=" flex-row justify-around m-4">
                      <CustomButton label={"Submit"} onClick={handleCreate} classname={"bg-blue-500 hover:bg-blue-600 block w-[100%] text-base"} />
                      <CustomButton label={"Close"} onClick={closeModal} classname={"bg-red-500 hover:bg-red-600 block w-[100%] mt-2 text-base"} />
                    </div>

                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null
        }

        <div className="rounded-b-md">
          {
            loading ? <Loader /> :
              <ReactDataTable columns={columns} data={data} height="70vh" />
          }
        </div>
      </div>
    </div >
  );
};
export default Companies;
