const tailwindClasses = {
    thClass: "Custoth p-2 ",
    tdClass: "border border-gray-950 p-2 ",
    textAreaClass: "w-full min-w-[16rem] min-h-[4rem] p-2 bg-gray-100 ",
    dropdown: "w-full rounded-[16px] h-[60px] sm:py-4 border border-gray-300 shadow-sm pl-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none",
    dateTimeInput: "w-full p-2 px-3 border border-gray-300 rounded-[16px] h-[60px] sm:py-4 items-center focus:outline-none",
    dropdownLabel: "font-semibold ms-3 mb-1",
    componentMainDiv: "md:shadow-2xl md:border md:rounded-[15px] md:m-4 bg-white py-4 text-xs min-h-[83vh]",
    componentHeaderDiv: "flex flex-col top-0 bg-white h-auto md:rounded-t-[20px]",
    componentHeaderFlexDiv:"px-4 w-full bg-white text-slate-500 flex flex-row justify-between"

}
export default tailwindClasses