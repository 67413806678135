// import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import MetaData from "../../components/MetaData";
import CustomButton from "../../components/Reusable/Button/CustomButton";
import { useState } from "react";
import axios from "axios";
import { makeUrl } from "../../assets/js/buildURL";
import toast from "react-hot-toast";
import { validateState } from "../../assets/js/helper";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/slices/userSlice";
import Logo from '../../assets/images/telemart.png'
import { encryptStorage } from "../../assets/js/encryptStorage";
export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        email: "",
        password: ""
        // email: "admin@telemarketing.com",
        // password: "123456"
    })

    const handelFormChange = (key, e) => {
        setFormData({ ...formData, [key]: e.target.value })
    }

    const handleClick = async () => {
        const validateStateRes = validateState(formData, ["email", "password"])
        if (validateStateRes) {
            return toast.error(validateStateRes)
        }
        const toastRef = toast.loading("loading")
        try {
            const Url = await makeUrl("user", "login")
            const res = await axios.post(Url, formData)
            console.log("login", res)
            const data = res?.data
            if (data?.role == "user") {
                encryptStorage.setItem("telemartToken", data?.token)
                encryptStorage.setItem("telemartRole", data?.role)
                dispatch(loginUser(data))
                toast.success("Login successfull")
                navigate("/companies")
            }
            else {
                encryptStorage.setItem("telemartToken", data?.token)
                encryptStorage.setItem("telemartRole", data?.role)
                dispatch(loginUser(data))
                toast.success("Login successfull")
                navigate("/admin/users")
            }

        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message

            console.error(errorMessage)
            toast.error(errorMessage)
        }
        toast.dismiss(toastRef)
    }

    return <div>
        <MetaData title={'Login'} />
        <div className="flex  flex-col absolute justify-center items-center min-h-full lg:min-h-screen w-full " >
            <div className=" bg-white py-4 px-[3rem] rounded-lg  md:shadow-2xl lg:w-1/3 h-fit overflow-y-auto " >
                {/* <form > */}
                <div className="w-[100%] flex justify-center items-center">
                    <img src={Logo} alt="" className="h-[10rem]" />
                </div>
                <h2 className="text-3xl font-bold text-slate-700 text-center"><span className="text-blue-500">Tele</span>market</h2>
                <h2 className="text-lg font-semibold  mt-4 mb-4 text-slate-500 text-center">Login To Your <span className="text-blue-500">Account !</span></h2>
                <input
                    className="w-[100%] border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3"
                    placeholder="Email"
                    value={formData.email}
                    type="email"
                    onChange={e => handelFormChange("email", e)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={e => handelFormChange("password", e)}
                    className="w-[100%] border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3 mt-3"

                />
                <div className="flex items-center justify-end gap-x-5 mt-4 mb-[4rem]">
                    <CustomButton
                        label={'Login'}
                        classname={"bg-green-500 hover:bg-green-600"}// color="green"
                        onClick={handleClick}
                    />

                    <CustomButton
                        label={'Go to signup'}
                        classname={"bg-blue-500 hover:bg-blue-600"} // color="green"
                        onClick={() => navigate("/signup")}
                    />
                </div>

                {/* </form> */}
            </div>
        </div>
    </div>
}