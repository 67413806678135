import axios from 'axios'
import React, { useEffect, useState } from 'react'
import config from '../../config'
import ReactDataTable from '../../components/ReactDataTable'
import CustomButton from '../../components/Reusable/Button/CustomButton'
import { useNavigate } from 'react-router-dom'
import { encryptStorage } from '../../assets/js/encryptStorage'
import toast from 'react-hot-toast'

const Users = () => {
    const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };
    const [userData, setUserData] = useState([])
    const nav = useNavigate()
    const getUserData = async () => {
        let res = await axios.get(`${config.BASE_URL}/user/getAllUserData`,{headers})
        console.log(res.data)
        setUserData(res?.data?.data)
    }

    const columns = [
        {
            name: "S No.",
            selector: (row, index) => index + 1,
            width: "80px",
            sortable: true,
        },
        {
            name: "User Name",
            selector: (row) => row?.name || "-",
            sortable: true,
            wrap: true,
        },
        {
            name: "User Email",
            selector: (row) => row?.email || "-",
            sortable: true,
            wrap: true,
        },
        {
            name: "Role",
            selector: (row) => row?.role || "-",
            sortable: true,
            wrap: true,
        },
        {
            name: "Account Status",
            selector: (row) => <p className={`${row.archive==0?"text-blue-500":"text-red-500"}`}>{row?.archive==0 ?"Activated":"Deactivated"}</p>,
            sortable: true,
            wrap: true,
        },
        {
            name: "Created At",
            selector: (row) => row?.created_at || "-",
            sortable: true,
            wrap: true,
        },
        {
            name: "Last Modified At",
            selector: (row) => row?.updated_at || "-",
            sortable: true,
            wrap: true,
        },
        {
            name: "Actions",
            height: "200px",
            selector: (row) =><CustomButton onClick={()=>handleActivationToogle(row.id,row?.archive)} classname={`${row.archive==0?"bg-red-500 hover:bg-red-600":"bg-blue-500 hover:bg-blue-600"}`} label={row.archive==0?"Deactivate":"Activate"}/>
            ,
            sortable: true,
            wrap: true,
        },

    ];

    const handleActivationToogle = async (id,status)=>{
        let loader = toast.loading("Loading")
        let updatedStatus = status==0 ?1 :0
        let res = await axios.put(`${config.BASE_URL}/user/toogle/${id}/${updatedStatus}`,{headers})
        console.log(res.data)
        if(res.data){
            toast.dismiss(loader)
            toast.success(res?.data?.message)
            getUserData()
        }
    }

    useEffect(() => {
        let role =  encryptStorage.getItem("telemartRole")
        if(role!="super_admin"){
            nav("/")
        }
        else{
            getUserData()
        }
    }, [])

    return (
        <div className='w-screen h-screen'>
            {/* TOPBAR  */}
            <div className="h-[4rem] border-b border-gray-300 bg-[#F1F2F4] flex justify-between items-center">
                <h1 className='px-3 text-2xl font-semibold'>Super Admin Dashboard</h1>
                <p onClick={()=>{localStorage.removeItem("telemartToken");localStorage.removeItem("telemartRole"); nav("/");}} className='px-3 cursor-pointer text-lg font-semibold'>Logout</p>
            </div>


            {/* DATATABLE  */}
            <ReactDataTable columns={columns} data={userData}  />

        </div>
    )
}

export default Users 