import DataTable from "react-data-table-component"
import React, { useState } from "react";
export default function ReactDataTable({ columns, data, height = "68vh" }) {

    const [filterText, setFilterText] = useState('');
    // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = data.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );
    const subHeaderComponentMemo = React.useMemo(() => {
        // const handleClear = () => {
        //     if (filterText) {
        //         setResetPaginationToggle(!resetPaginationToggle);
        //         setFilterText('');
        //     }
        // };

        return (
            <div >
                <input className="border border-[lightgray] w-[13rem] h-[2.3rem] px-3 rounded-md text-black outline-gray-300" type="text" placeholder="Search By Name" value={filterText} onChange={e => setFilterText(e.target.value)} style={{ marginRight: '10px' }}/>
                {/* <button onClick={handleClear}>Clear</button> */}
            </div>
        );
    }, [filterText]);

    return (
        <div className="flex flex-col p-4 items-center justify-center text-base">
            <DataTable
                // title="Movie List"
                columns={columns}
                data={filterText ? filteredItems : data}
                pagination
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead

                fixedHeader
                fixedHeaderScrollHeight={`calc(${height} - 14vh)`}
                // persistTableHead={true} G
                // columns={columns}
                // data={data}
                // pagination={true}
                noHeader={true}
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100, 1000, 10000]}
                customStyles={{
                    headRow: {
                        borderBottom: "2px solid #ddd",
                    },
                    headCells: {
                        backgroundColor: "#f2f2f2",
                        fontWeight: "bold",
                    },
                }}
            />
        </div>
    );
}