import RouteList from './Routes';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import project from '../package.json'
import config from './config';
const { ENVIRONMENT } = config
console.log("version", project.version)
console.log("env", ENVIRONMENT)

function App() {

  return (
    <Router>
      <RouteList />
    </Router>
  );
}

export default App;
