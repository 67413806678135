import config from "../../config";

function capitalize(s) {
    if (!s) {
        return
    }
    return s[0].toUpperCase() + s.slice(1);
}

const log = (data, message = null) => {
    if (config.ENVIRONMENT !== "prod") {
        if (message) {
            console.log(`log: ${message}: ${JSON.stringify(data)} `)
        }
        else {
            console.log(`log: ${JSON.stringify(data)} `)
        }
    }
}

const getCustomerAcl = async (reduxClient, reduxSloifId) => {
    let customer = await reduxClient.Query({
        collectionname: "users",
        query: { _type: "customer", customattr1: reduxSloifId },
    });
    customer = customer[0];

    const _acl = [
        {
            _id: customer.admins,
            name: customer.name + " admins",
            rights: 65535,
        },
        {
            _id: customer.users,
            name: customer.name + " users",
            rights: 6,
        },
        {
            _id: "5a1702fa245d9013697656fb",
            name: "admins",
            rights: 65535,
        },
    ];

    return _acl;
};

function validateState(state, keysToCheck) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (let key of keysToCheck) {
        if (!state.hasOwnProperty(key)) {
            return `Error: Key '${key}' is missing from the state.`;
        }
        if (state[key] === null || state[key] === undefined || state[key] === '') {
            return `Error: '${key}' is empty or null.`;
        }
        if (key === 'email' && !emailRegex.test(state[key])) {
            return `Error: Invalid email address.`;
        }
    }
    return false;
}

export {
    capitalize,
    log,
    getCustomerAcl,
    validateState,
}