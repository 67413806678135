import React from "react";

const CustomButton = ({ label, onClick, classname, loading = null }) => {
  return (
    <button
      onClick={onClick}
      disabled={loading}
      className={`text-white font-bold py-2 px-4 rounded ${classname} ${loading ? "opacity-50 cursor-not-allowed pointer-events-none" : ""}`}
    >
      {label}
    </button>
  );
};

export default CustomButton;
