// import { useEffect } from 'react'
// import { toast } from 'react-hot-toast';

import { useDispatch, useSelector } from "react-redux";
import { makeUrl } from "../assets/js/buildURL";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { encryptStorage } from "../assets/js/encryptStorage";
import { getCompanyDetails } from "../store/slices/companySlice";
import { getUserData } from "../store/slices/userSlice";
import Tippy from "@tippyjs/react";

function Header({ user }) {
  const dispatch = useDispatch()
  // const [data, setData] = useState([])
  const [showCompanies, setshowCompanies] = useState(false)
  const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };
  const reduxCompanies = useSelector(state => state.companyReducer.allCompanies)
  const reduxCompanyId = useSelector(state => state.companyReducer.selectedCompanyId)
  const reduxUserData = useSelector(state => state.userReducer.userData)
  // console.log("reduxUserData", reduxUserData)
  // console.log("telemartToken", encryptStorage.getItem('telemartToken'))
  // console.log("reduxUserData", encryptStorage.getItem('telemartRole'))

  const handleActivateCompany = async (currentId, newId) => {
    if (currentId == newId) { setshowCompanies(false); return }
    else {
      try {
        const URL = makeUrl("company", `activate/company/${currentId}/${newId}`)
        const res = await axios.get(URL, { headers })
        if (res.status !== 200) {
          setshowCompanies(false)
          return toast.error(`Error fetching data status ${res.status}`)
        }
        else {
          dispatch(getCompanyDetails())
          setshowCompanies(false)
          toast.success("Company Activated")
        }
      }
      catch (e) {
        setshowCompanies(false)
        toast.error(`Error fetching data: ${e.message}`)
        console.error("getAllData Error: ", e.message);
      }
    }
  }


  useEffect(() => {
    (async () => {
      await dispatch(getCompanyDetails())
      await dispatch(getUserData())
    })()
  }, [])

  return (
    <div className='bg-white'>
      <div className="pt-0 pr-0 pb-0 pl-0 mt-0 mr-0 mb-0 ml-0"></div>
      <div className="w-full border-gray-200">
        <div className="h-12 flex justify-between items-center mx-auto px-4">

          <div className="space-x-6 justify-end items-center ml-auto flex relative">

            <div className="justify-center items-center flex">
              <Tippy content={reduxUserData?.email}>
                <div className="flex h-9 w-9 rounded-full mr-0 bg-gray-300 items-center justify-center font-bold me-2 cursor-pointer" >
                  {reduxUserData?.email ? reduxUserData?.email[0].toUpperCase() : "-"}
                </div>
              </Tippy>
            </div>


            <div onClick={() => setshowCompanies(!showCompanies)} className="w-[10rem] h-[2rem] border border-[lightgray] rounded-md flex justify-center items-center cursor-pointer">
              {
                reduxCompanies?.map((i, index) => {
                  return i?.selected_company == 1 && (<p className="text-black max-w-[7rem] truncate cursor-pointer">{i.name}</p>)
                })
              }
            </div>

            {
              showCompanies && reduxCompanies?.length > 0 && (
                <div className=" absolute top-[2.5rem] right-0 w-[10rem] overflow-y-auto max-h-[10rem] border border-[lightgray] bg-white rounded-md z-50 ">
                  {
                    reduxCompanies?.map((i) => (
                      <p onClick={() => handleActivateCompany(reduxCompanyId, i.id)} className={`text-[0.8rem] mb-0  border-b cursor-pointer border-gray-300 px-3 py-2 ${i.selected_company == 1 ? "bg-sky-500 text-white" : "cursor-pointer text-black"}`}>{i.name}</p>
                    ))
                  }
                </div>
              )
            }

          </div>


        </div>
      </div>

      <hr className='block lg:hidden' />
      <hr className='block lg:hidden' />
      <hr className='block lg:hidden' />
    </div>
  );
}

export default Header;