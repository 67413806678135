/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector } from "react-redux";
// import { useCallback } from "react";
import toast from "react-hot-toast";
import MetaData from "../../components/MetaData";
import { confirmAlert } from "react-confirm-alert";
// import CustomButton from "../../components/Reusable/Button/CustomButton";
import Loader from "../../components/Loader";
import ReactDataTable from "../../components/ReactDataTable";
import tailwindClasses from "../../assets/tailwindClasses";
import axios from "axios";
import { makeUrl } from "../../assets/js/buildURL";
import { log, validateState } from "../../assets/js/helper";
import CustomButton from "../../components/Reusable/Button/CustomButton";
import { encryptStorage } from "../../assets/js/encryptStorage";
import { decodeToken } from "../../utils/token";

const { componentMainDiv, componentHeaderDiv, componentHeaderFlexDiv } = tailwindClasses;

const Contacts = () => {

  const reduxCompanyId = useSelector(state => state.companyReducer.selectedCompanyId)
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({list_id: null});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  let list_id = null
  const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };
  const columns = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      width: "80px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Phone No.",
      selector: (row) => row?.phone_number || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Mobile No.",
      selector: (row) => row?.mobile_number || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Last call status",
      selector: (row) => row?.last_call_status || "-",
      sortable: true,
      wrap: true,
      width: "140px"
    },
    {
      name: "Next call date",
      selector: (row) => row?.next_call_date || "-",
      sortable: true,
      wrap: true,
      width: "140px"
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Last Modified At",
      selector: (row) => row?.updated_at || "-",
      sortable: true,
      wrap: true,
      width: "150px"
    },
    {
      name: "Actions",
      selector: (row) => <CustomButton
        label={"Call"}
        classname={"bg-blue-500 hover:bg-blue-600"}
        onClick={() => {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="bg-white border-2 rounded-2xl">
                  <h1 className="pt-4 px-4 my-4 font-bold">
                    Call
                  </h1>
                  <hr />
                  <p className="px-4 my-4">
                    Are you sure you want to initiate a call?
                  </p>
                  <hr />
                  <div className="flex flex-row justify-between my-2 pb-4 px-4">
                    <CustomButton
                      label={"Yes"}
                      classname={"bg-green-500 hover:bg-green-600"}

                      onClick={async () => {
                        const toastref = toast.loading("Calling...")
                        try {
                          const URL = makeUrl("call", "create")
                          log(URL, "URL")
                          const res = await axios.post(URL, {
                            "customerNumber": row?.phone_number ? row?.phone_number : row.mobile_number,
                            "contact_id": row.id
                          }, { headers })
                          console.log(res.data)
                          toast.success("Call successfully created")
                        } catch (error) {
                          console.error(error.message)
                          toast.error(error.message)
                        }
                        toast.dismiss(toastref)
                        onClose();
                        // handleYes(onClose)
                      }}
                    />
                    <CustomButton
                      classname={"bg-red-500 hover:bg-red-600"}
                      label={"No"}
                      onClick={onClose}
                    />
                  </div>
                </div>
              );
            },
          });
        }}
      />,
      sortable: true,
      wrap: true,
    },
  ];

  const getAllData = async () => {
    try {
      const URL = makeUrl("data", `getContacts/${reduxCompanyId}`)
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      const data = res.data.data
      setData(data);
    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`)
      console.error("getAllOrganiations Error: ", e.message);
    }
    setLoading(false)
  };

  const deleteData = async () => {
    setLoading(true)
    const toastRef = toast.loading('loading')
    try {
      let tokenInfo = await decodeToken(encryptStorage.getItem("telemartToken"));
      console.log(tokenInfo,'tokenInfo')
      const URL = makeUrl("data", `deleteContacts/${tokenInfo?.id}`)
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      toast.success("Successfully deleted")
      setRefresh(!refresh)

    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`)
      console.error("deleteData Error: ", e.message);
    }
    toast.remove(toastRef)
    setLoading(false)
  };

  const handelFormChange = (key, e) => {
    setFormData({ ...formData, [key]: e.target.value })
  }

  const resyncData = async (onClose) => {
    console.log("list_id", list_id)
    if (!list_id) {
      return toast.error("List id is missing")
    }
    setLoading(true)
    const toastId = toast.loading("Syncing")
    try {
      const URL = makeUrl("data", `resyncContacts/${reduxCompanyId}/${list_id}`)
      const res = await axios.get(URL, { headers })
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`)
      }
      toast.success("Successfully synced")
      setRefresh(!refresh)
      setFormData({ listId: null })

    } catch (e) {
      toast.error(`Error fetching data: ${e.response.data.message}`)
      console.error("resyncData Error: ", e.response.data.message);
    }
    toast.dismiss(toastId)
    setLoading(false)
    onClose()
  };

  useEffect(() => {
    getAllData();
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [refresh, reduxCompanyId]);

  return (
    <div className="min-h-[89%]">
      <MetaData title={"Home"} />

      <div className={componentMainDiv}>

        <div className={componentHeaderDiv}>
          <div className={componentHeaderFlexDiv}>
            <div className="text-2xl">All Hubspot Contacts</div>
            <div className="flex items-center gap-x-4">
              <button label="Delete all data" className={`text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded ${loading ? "opacity-50 cursor-not-allowed pointer-events-none" : ""}`} onClick={() => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="bg-white border-2 rounded-2xl">
                        <h1 className="pt-4 px-4 my-4 font-bold">
                          Delete!
                        </h1>
                        <hr />
                        <p className="px-4 my-4">
                          Are you sure you want to delete all contacts?
                        </p>
                        <p className="px-4 my-4">
                          This action is not reversable!
                        </p>
                        <hr />
                        <div className="flex flex-row justify-between my-2 pb-4 px-4">
                          <CustomButton
                            label={"Confirm"}
                            classname={"bg-green-500 hover:bg-green-600"}
                            onClick={async () => {
                              await deleteData()
                              onClose()
                            }}
                          />
                          <CustomButton
                            label={"Cancel"}
                            classname={"bg-red-500 hover:bg-red-600"}
                            onClick={onClose}
                          />
                        </div>
                      </div>
                    );
                  },
                })
              }} loading={loading}>Delete all data</button>
              <CustomButton label="Resync data" className="m-auto" onClick={async () => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="bg-white border-2 rounded-2xl">
                        <h1 className="pt-4 px-4 my-4 font-bold">
                          Alert!
                        </h1>
                        <hr />
                        <p className="px-4 my-4">
                          Are you sure you want to resync data? This will delete the previous data.
                        </p>
                        <label
                          className="ms-4"
                        >List Id: </label>
                        <input
                          value={list_id}
                          onChange={e => {
                            handelFormChange("list_id", e)
                            list_id = e.target.value
                          }}
                          type="number"
                          className=" border border-[1px solid ligtgray] h-[2.3rem] outline-none rounded-md px-3 m-4"
                          placeholder="List id"
                        />
                        <hr />
                        <div className="flex flex-row justify-between my-2 pb-4 px-4">
                          <CustomButton
                            label={"Confirm"}
                            classname={"bg-green-500 hover:bg-green-600"}
                            onClick={async () => {
                              await resyncData(onClose)
                            }}
                          />
                          <CustomButton
                            label={"Cancel"}
                            classname={"bg-red-500 hover:bg-red-600"}
                            onClick={() => {
                              onClose()
                              setFormData({})
                            }}
                          />
                        </div>
                      </div>
                    );
                  },
                })
              }} loading={loading} classname={"bg-blue-500 hover:bg-blue-600"} />
            </div>

          </div>
        </div>

        <div className="rounded-b-md">
          {
            loading ? <Loader /> :
              <ReactDataTable columns={columns} data={data} height="70vh" />
          }
        </div>
      </div>
    </div>
  );
};
export default Contacts;
