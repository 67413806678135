import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  persistStore,
} from "redux-persist";
// import createEncryptor from 'redux-persist-transform-encrypt'
import rootReducer from "./rootReducer";

// const encryptor = createEncryptor({
//   secretKey: 'lenovo-alpha-mart-six'
// })

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
});

const persistor = persistStore(store);

export {
  store,
  persistor
};