import React from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer >
      <div className='flex flex-row items-center justify-center text-black m-2 '>
        Copyright &copy; {currentYear}. All Rights Reserved.
      </div>
    </footer>
  )
}

export default Footer