import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'
import { makeUrl } from '../../assets/js/buildURL'
import axios from 'axios'
import { encryptStorage } from '../../assets/js/encryptStorage';
import toast from 'react-hot-toast';


const initialState = {
    selectedCompanyId: "",
    selectedCompanyName: "",
    allCompanies: []
}

export const getCompanyDetails = createAsyncThunk(
    "getCompanyDetails",
    async (user, { getState }) => {
        let data = []
        const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };

        try {
            const URL = makeUrl("company", `getAllData`)
            const res = await axios.get(URL, { headers })
            if (res.status !== 200) {
                return toast.error(`Error fetching data status ${res.status}`)
            }
            data = res?.data?.data
        }
        catch (e) {
            toast.error(`Error fetching data: ${e.message}`)
            console.error("getAllData Error: ", e.message);
        }
        return data
    }
)

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        setSelectedCompanyId: (state, action) => {
            state.selectedCompanyId = action.payload
        },
        removeSelectedCompanyId: (state, action) => {
            state.selectedCompanyId = ""
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyDetails.fulfilled, (state, action) => {
            // state.selectedCompanyId = action.payload
            const data = action.payload
            data?.map((i) => {
                if (i.selected_company == 1) {
                    state.selectedCompanyId = i?.id;
                    state.selectedCompanyName = i?.name;
                }
            })
            state.allCompanies = data
        })
    }
})

export const { setSelectedCompanyId, removeSelectedCompanyId } = companySlice.actions
export default companySlice.reducer