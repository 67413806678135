const config_dev = {
    BASE_URL: "http://localhost:5000/api",
    ENVIRONMENT: "dev",
    encryption_key: "telemarketing_secret_key_v1",
}

// const config_staging = {
//     BASE_URL: "http://localhost:5000/api",
//     ENVIRONMENT: "staging",
//     encryption_key: "telemarketing_secret_key_v1",
// }

const config_prod = {
    BASE_URL: "https://telemarket.eusopht.com/apis/api",
    ENVIRONMENT: "prod",
    encryption_key: "telemarketing_secret_key_v1",
}

// export default config_dev
export default config_prod