import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function NoMatch() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <div>
            <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 h-screen">
                <div className="text-center">
                    <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">404</h1>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                    <p className="mt-6 text-base leading-7">Sorry, we couldn’t find the page you’re looking for.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link to="/" className="rounded-md bg-gradient-to-r from-yellow-500 from-10% via-purple-500 via-50% to-white-500 to-120%  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            Go back home
                        </Link>
                    </div>
                </div>
            </main>
        </div>
    );
}