/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import MetaData from "../../components/MetaData";
import Loader from "../../components/Loader";
import ReactDataTable from "../../components/ReactDataTable";
import tailwindClasses from "../../assets/tailwindClasses";
import axios from "axios";
import { makeUrl } from "../../assets/js/buildURL";
import CustomButton from "../../components/Reusable/Button/CustomButton";
import { encryptStorage } from "../../assets/js/encryptStorage";
import { capitalize } from "@mui/material";

const { componentMainDiv, componentHeaderDiv, componentHeaderFlexDiv } = tailwindClasses;

const Reports = () => {
  const reduxCompanyId = useSelector(state => state.companyReducer.selectedCompanyId)
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({ text: "", headding: "" })
  const [showModal, setShowModal] = useState(false);
  const [duration, setDuration] = useState('')
  const [callStatus, setCallStatus] = useState('')
  const headers = { authorization: `Bearer ${encryptStorage.getItem('telemartToken')}` };


  const [selectedStatus, setSelectedStatus] = useState("All")
  const [selectedDate, setSelectedDate] = useState("All")
  const [showStatus, setShowStatus] = useState(false)
  const [showDate, setShowDate] = useState(false)




  const columns = [
    {
      name: "S No.",
      selector: (row, index) => index + 1,
      width: "80px",
      sortable: true,
    },
    {
      name: "cid",
      selector: (row) => row?.contact_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Vapi Call Status",
      selector: (row) => row?.vapi_call_status || "-",
      sortable: true,
      wrap: true,
      width: "140px"
    },
    {
      name: "Status",
      selector: (row) => row?.status || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Transcript",
      selector: (row) => row.transcript != null ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(row.transcript, "transcript")} /> : "-",
      sortable: true,
      wrap: true,
      width: "120px"
    },
    {
      name: "Summary",
      selector: (row) => row.summary != null ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(row.summary, "summary")} /> : "-",
      sortable: true,
      wrap: true,
      width: "120px"
    },
    {
      name: "Customer Response",
      selector: (row) => row?.customer_response || "-",
      sortable: true,
      wrap: true,
      width: "170px"
    },
    {
      name: "Call End Reason",
      selector: (row) => row?.call_end_reason || "-",
      sortable: true,
      wrap: true,
      width: "144px"
    },
    {
      name: "Call End Time",
      selector: (row) => row?.call_end_time || "-",
      sortable: true,
      wrap: true,
      width: "130px"
    },

    {
      name: "Last Modified At",
      selector: (row) => row?.updated_at || "-",
      sortable: true,
      wrap: true,
      width: "150px"
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at || "-",
      sortable: true,
      wrap: true,
      width: "120px"
    },
    {
      name: "Call Start Data",
      selector: (row) => (row.call_start_data != null) ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(JSON.stringify(row?.call_start_data), "Call Start Data")} /> : "-",
      sortable: true,
      wrap: true,
      width: "150px"
    },
    {
      name: "Call End Data",
      selector: (row) => (row.call_end_data != null) ? <CustomButton label={"View"} classname={"bg-blue-500"} onClick={() => handleClick(JSON.stringify(row?.call_end_data), "Call End Data")} /> : "-",
      sortable: true,
      wrap: true,
      width: "150px"
    },
    {
      name: "Meeting Status",
      selector: (row) => row?.meeting_status || "-",
      sortable: true,
      wrap: true,
      width: "130px"
    },
    {
      name: "Sentiment",
      selector: (row) => row?.sentiment || "-",
      sortable: true,
      wrap: true,
      width: "130px"
    }
  ];

  const handleClick = (text, headding) => {
    setModalData({ text: text, headding: headding })
    setShowModal(true)
  }
  const getAllCallLogs = async () => {
    setLoading(true); // Ensure loading is set to true while fetching data
  
    try {
      const URL = makeUrl("data", `getCallLogs/${reduxCompanyId}`);
      const res = await axios.get(URL, { headers });
  
      if (res.status !== 200) {
        return toast.error(`Error fetching data status ${res.status}`);
      }
  
      const data = res.data.data;
  
      // Start with the original data
      let filteredData = data;
  
      // Apply date filter if not "All"
      if (selectedDate !== "All") {
        const now = new Date();
        filteredData = filteredData.filter(item => {
          if (!item.created_at) return false; // Skip items without created_at
          const date = new Date(item.created_at);
          switch (selectedDate) {
            case "Today":
              return date.toDateString() === now.toDateString();
            case "This Week":
              const startOfWeek = new Date(now);
              startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
              const endOfWeek = new Date(startOfWeek);
              endOfWeek.setDate(endOfWeek.getDate() + 6);
              return date >= startOfWeek && date <= endOfWeek;
            case "This Month":
              return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
            default:
              return true; // If not a valid option, return all
          }
        });
      }
  
      // Apply status filter if not "All"
      if (selectedStatus !== "All") {
        filteredData = filteredData.filter(item => {
          console.log(item.meeting_status,'item.meeting_status')
          if (!item.meeting_status) return false;
          switch (selectedStatus) {
            case "Success":
              return item.meeting_status.includes("Meeting fixed");
            case "Failed":
              return item.meeting_status.includes("User did not pick the call");
            case "Rejected":
              return item.meeting_status.includes("User rejected the proposal");
            case "Reschedule":
              return item.meeting_status.includes("User reschedule the meeting");
            default:
              return true; // If not a valid option, return all
          }
        });
      }
  
      // Set the filtered data
      setData(filteredData);
    } catch (e) {
      toast.error(`Error fetching data: ${e.message}`);
      console.error("getAllCallLogs Error: ", e.message);
    } finally {
      setLoading(false); // Ensure loading is set to false after fetching
    }
  };
  

  const closeModal = () => {
    setShowModal(false)
  }

  const statusArr = ["All", "Success", "Failed", "Rejected", "Reschedule"]
  const dateArr = ["All", "Today", "This Week", "This Month"]

  useEffect(() => {
    getAllCallLogs();
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [refresh, reduxCompanyId, selectedDate, selectedStatus]);




  return (
    <div className="min-h-[89%]">
      <MetaData title={"Call Logs"} />

      <div className={componentMainDiv}>

        <div className={componentHeaderDiv}>
          <div className={componentHeaderFlexDiv}>

            <div className="text-2xl">Call Reports</div>

            <div className="flex items-center gap-x-6">

              <div className=" relative">

                <div onClick={() => setShowDate(!showDate)} className="w-[10rem] h-[2rem] border border-[lightgray] rounded-md flex justify-center items-center cursor-pointer">
                  <p className="text-black max-w-[7rem] truncate cursor-pointer">{selectedDate}</p>
                </div>

                {
                  showDate &&
                  <div className="h-[fit] absolute top-[2.5rem] right-0 w-[10rem] overflow-y-auto  border border-[lightgray] bg-white rounded-md z-50 ">
                    {dateArr.map((i) => (
                      <p onClick={() => { setSelectedDate(i); setShowDate(false) }} key={i} className={`text-[0.8rem] mb-0  border-b cursor-pointer border-gray-300 px-3 py-2 ${i == selectedDate ? "bg-sky-500 text-white" : "cursor-pointer text-black"}`}>{i}</p>
                    ))}
                  </div>
                }

              </div>

              <div className=" relative">

                <div onClick={() => setShowStatus(!showStatus)} className="w-[10rem] h-[2rem] border border-[lightgray] rounded-md flex justify-center items-center cursor-pointer">
                  <p className="text-black max-w-[7rem] truncate cursor-pointer">{selectedStatus}</p>
                </div>
                {
                  showStatus &&

                  <div className="h-[fit] absolute top-[2.5rem] right-0 w-[10rem] overflow-y-auto  border border-[lightgray] bg-white rounded-md z-50 ">
                    {statusArr.map((i) => (
                      <p onClick={() => { setSelectedStatus(i); setShowStatus(false) }} key={i} className={`text-[0.8rem] mb-0  border-b cursor-pointer border-gray-300 px-3 py-2 ${i == selectedStatus ? "bg-sky-500 text-white" : "cursor-pointer text-black"}`}>{i}</p>
                    ))}
                  </div>
                }

              </div>

            </div>

          </div>
        </div>

        <div className="rounded-b-md">
          {
            loading ? <Loader /> :
              <ReactDataTable columns={columns} data={data} height="70vh" />
          }
        </div>
      </div>

      {
        showModal ? (
          <>
            <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
              <div className=" my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg  flex flex-col  bg-white outline-none focus:outline-none ">

                  <div className="flex flex-col items-center justify-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <label className="text-3xl font-semibold">{capitalize(modalData?.headding)}</label>
                  </div>

                  <div className="flex flex-col items-start p-5 border-b border-solid border-blueGray-200 rounded-t max-h-80 overflow-y-auto">
                    {modalData?.text}
                  </div>

                  <div className=" flex-row justify-around m-4">
                    <CustomButton label={"Close"} onClick={closeModal} classname={"bg-red-500 hover:bg-red-600 block w-[100%] mt-2 text-base"} />
                  </div>

                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null
      }
    </div>
  );
};
export default Reports;
