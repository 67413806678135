import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'
import {
  store,
  persistor
} from '../src/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Toaster } from "react-hot-toast";
import 'react-confirm-alert/src/react-confirm-alert.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    <PersistGate persistor={persistor}>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <App />
    </PersistGate>
  </Provider>
);
