// import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { encryptStorage } from "../assets/js/encryptStorage";

const Layout = () => {
  const nav = useNavigate()

  if (!encryptStorage.getItem("telemartToken")) {
    return nav("/")
  }

  return (
    <Disclosure as="nav">
      <div className="lg:grid lg:grid-cols-6 bg-gray-100 min-h-screen text-xs">
        <div className="lg:col-span-1">
          <Sidebar />
        </div>
        <div className="lg:col-span-5 flex flex-col">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </div>

    </Disclosure>
  );
};

export default Layout;
